import React from "react"

import PageWrapper from "../../components/PageWrapper"
import SEO from "../../components/SEO"
import Content from "../../sections/industries/Content"
import Hero from "../../sections/industries/Hero"
import { t } from "@lingui/macro"

const Industry = ({ location }) => {
  return (
    <>
      <PageWrapper headerDark>
        <SEO
          pathname={location.pathname}
          title={t`Industrien`}
          description={t`In den vergangenen drei Jahren haben wir profitable KI-Projekte in vielen Industrien umgesetzt. Hier skizzieren wir Anwendungsfälle in Logistik, Telekommunikation und Versorgungswirtschaft.`}
        />
        <Hero />
        <Content />
      </PageWrapper>
    </>
  )
}
export default Industry
