import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Trans } from "@lingui/macro"

//import { Button } from "../../components/Core";
import FixedHeroImage from "../../components/FixedHeroImage"
import { Title } from "../../components/Core"
import { getImage } from "gatsby-plugin-image"

const IndustryBanner = styled(FixedHeroImage)`
  height: 700px;
  max-height: 100vh;
  font-size: 31px;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Hero = () => {
  const { hero } = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "jpeg/industry_sky.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `,
  )

  const backgroundFluidImageStack = [
    `linear-gradient(180deg,rgba(0,0,0,.4),rgba(0,0,0,.4))`,
    getImage(hero.childImageSharp.gatsbyImageData),
  ]
  console.log("Test")
  return (
    <>
      <IndustryBanner Tag="section" image={backgroundFluidImageStack}>
        <Title className="text-center" variant="hero" css={{ color: "white" }}>
          <Trans>Maß​geschneiderte KI-Lösungen</Trans> <Trans>für Ihre Branche</Trans>
        </Title>
      </IndustryBanner>
    </>
  )
}

export default Hero
