import React, { useContext } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

import { Title, Button, Section, Box, Text } from "../../components/Core"

import GlobalContext from "../../context/GlobalContext"

import imgLogistics from "../../assets/image/jpeg/industry-logistics.jpg"
import imgUtilities from "../../assets/image/jpeg/industry-utilities.jpg"
import imgTelecom from "../../assets/image/jpeg/industry-telecommunications.jpg"

const Content = () => {
  const gContext = useContext(GlobalContext)

  return (
    <>
      <Section id="logistics">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="5" className="mb-5 offset-lg-1 order-lg-2 mb-lg-0">
              <div>
                <div
                  data-aos="zoom-out"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500">
                  <Link to="/industries/logistics">
                    <Box
                      borderRadius={4}
                      className="overflow-hidden position-relative"
                      height="600px"
                      maxHeight="60vh">
                      <img
                        src={imgLogistics}
                        alt=""
                        className="w-100 h-100"
                        style="filter: brightness(1.2) contrast(1.01) grayscale(0.5); grayscale(0.5); object-fit: cover; object-position: bottom;"
                      />
                    </Box>
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              className="col-md-10 col-lg-6 order-lg-1"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true">
              <div>
                <Title variant="hero" color="dark">
                  <Trans>Logistik</Trans>
                </Title>
                <Text color="darkShade">
                  <Trans>
                    KI revolutioniert die Logistik. Von Predictive Analytics über autonome Fahrzeuge bis hin zu Robotern verändert KI grundlegend wie Waren von A nach B transportiert werden.
                  </Trans>
                </Text>
                <div className="mt-5">

                  <Link to="/industries/logistics">
                    <Button className="text-wrap col-12 col-lg-10 pr-lg-5">
                      <Trans>Mehr über KI in der Logistik</Trans>
                    </Button>
                  </Link>
                </div>
                <div className="mt-3">
                  <Link to="/contact/?utm_source=logistics">
                    <Button variant="outline" fontSize="16px" color="primary" className="text-wrap col-12 col-lg-10 pr-lg-5">
                      <Trans>Direkt kostenfreie Erstberatung zu KI Anwendungen in Ihrer Logistik buchen</Trans>
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section bg="#F9F9F9" id="utilites">
        {/*blue shade F7FBFD grey shade F9F9F9*/}
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="5" className="mb-5 order-lg-1 mb-lg-0">
              <div>
                <div
                  data-aos="zoom-out"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500">
                  <Box
                    borderRadius={4}
                    className="overflow-hidden position-relative"
                    height="600px"
                    maxHeight="60vh">
                    <img
                      src={imgUtilities}
                      alt=""
                      className="w-100 h-100"
                      style="filter: brightness(1.2) contrast(1.01) grayscale(0.5); grayscale(0.5); object-fit: cover; object-position: bottom;"
                    />
                  </Box>
                </div>
              </div>
            </Col>
            <Col
              md="10"
              lg="6"
              className="order-lg-7 offset-lg-1 "
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true">
              <div>
                <Title variant="hero" color="dark">
                  <Trans>Versorgung</Trans>
                </Title>
                <Text color="darkShade">
                  <Trans>
                    Vorhersage, intelligente Nachfragesteuerung, Lastausgleich, Chatbots und vieles mehr. Wir entwickeln Machine Learning Lösungen, die Ihr Energienetz widerstandsfähiger machen und Ihre Kunden glücklich.
                  </Trans>
                </Text>
                <div className="mt-5">
                  {/*<ADD ONCE SUB PAGE IS COMPLETEDButton className="text-wrap mw-100 pr-lg-5">Learn more about AI in Utilities</Button>*/}
                  <Link to="/contact/?utm_source=utilities">
                    <Button className="text-wrap mw-100 pr-lg-5">
                      <Trans>Kostenfreie Erstberatung zu KI Anwendungen in der Versorgungswirtschaft</Trans>
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section id="telecom">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="5" className="mb-5 offset-lg-1 order-lg-2 mb-lg-0">
              <div>
                <div
                  data-aos="zoom-out"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500">
                  <Box
                    borderRadius={4}
                    className="overflow-hidden position-relative align-items-end"
                    height="600px"
                    flex-direction="column"
                    maxHeight="60vh">
                    <img
                      src={imgTelecom}
                      alt=""
                      className="w-100 h-100"
                      style="filter: brightness(1.2) contrast(1.01) grayscale(0.5); grayscale(0.5); object-fit: cover; object-position: bottom;"
                    />
                  </Box>
                </div>
              </div>
            </Col>
            <Col
              md="10"
              lg="6"
              className="order-lg-1"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true">
              <div>
                <Title variant="hero" color="dark">
                  <Trans>Kommunikation</Trans>
                </Title>
                <Text color="darkShade">
                  <Trans>
                    Automatisierte Customer Care Services, Churn Forecasting, Predictive Maintenance und vieles mehr. Wir helfen Ihnen, effizienter zu arbeiten und Telekommunikation kundenfreundlicher zu gestalten.
                  </Trans>
                </Text>
                <div className="mt-5">
                  {/* ADD ONCE SUB PAGE IS COMPLETED <Button className="text-wrap mw-100 pr-lg-5">Learn more about AI in Telecom</Button>*/}
                  <Link to="/contact/?utm_source=telecom">
                    <Button className="text-wrap mw-100 pr-lg-5">
                      <Trans>Kostenfreie Erstberatung zu Anwendungen in der Telekommunikation</Trans>
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Content
